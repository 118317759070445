import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function NewsGallery({folder, data, children }) {

  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(
      data.map(item => `/aktuality/${folder}/${item}.jpg`)
    )
  },[]);

  return (
    <>
      { isOpen && (
        <Lightbox
          reactModalProps={{ shouldReturnFocusAfterClose: false }}
          enableZoom={false}
          mainSrc={images[selected]}
          nextSrc={images[(selected + 1) % data.length]}
          prevSrc={images[(selected + data.length - 1) % data.length]}
          onCloseRequest={e => { setOpen(false);
          }}
          onMovePrevRequest={() =>
            setSelected((selected + data.length - 1) % data.length)
          }
          onMoveNextRequest={() =>
            setSelected((selected + 1) % data.length)
          }
        />
      )}
      <div onClick={() => setOpen(true)}>
        {children}
      </div>
    </>
  );
}

export default NewsGallery;